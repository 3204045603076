import { config } from 'config';
import { FetchOptions, getAuthHeaders } from 'api/fetch';

type PersonCreationValues = {
  first_name?: string;
  last_name?: string;
  email?: string;
  roles: Array<string>;
};
export const createPersonCreateRequest = (
  businessId: number | undefined,
  token: string,
  data: PersonCreationValues,
): FetchOptions => ({
  url: `${config.apiBaseURL}/business/customer/businesses/${businessId ?? ''}/persons`,
  method: 'POST',
  headers: getAuthHeaders(token),
  body: JSON.stringify(data),
});

export const createPersonRolesPatchRequest = (appId: number, token: string): FetchOptions => ({
  url: `${config.apiBaseURL}/laas-application/borrower/lending/applications/${appId}/auth_rep`,
  method: 'POST',
  headers: getAuthHeaders(token),
  body: JSON.stringify({}),
});
